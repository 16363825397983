import React, { Component } from "react";
import { API, Storage } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import {Col, Row, Grid} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./Notes.css";

export default class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: null,
      isDeleting: null,
      note: null,
      content: "",
      noteName: "",
      collaborator: "",
      sharedWith: ""
    };
  }

  async componentDidMount() {
    try {
      const note = await this.getNote();
      const { content, noteName, collaborator, sharedWith } = note;

      this.setState({
        note,
        content,
        noteName,
        collaborator,
        sharedWith
      });
    } catch (e) {
      alert(e);
    }
  }

  getNote() {
    return API.get("notes", `/notes/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.content.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  saveNote(note) {
    return API.put("notes", `/notes/${this.props.match.params.id}`, {
      body: note
    });
  }

  handleSubmit = async event => {

    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.saveNote({
        content: this.state.content,
        noteName: this.state.noteName,
        collaborator: this.state.collaborator,
        sharedWith: this.state.sharedWith
      });
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }


  deleteNote() {
    return API.del("notes", `/notes/${this.props.match.params.id}`);
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteNote();
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  render() {
    return (
      <div className="Notes">
        <h4>{this.state.noteName}</h4>
        {this.state.note &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="content" className="notesContent">
              <FormControl
                onChange={this.handleChange}
                value={this.state.content}
                componentClass="textarea"
              />
            </FormGroup>
            <LoaderButton
              block
              bsSize="lg"
              variant="outline-secondary"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
          </form>}
      </div>
    );
  }

  /*render() {
    return (
      <div className="Notes">
        <Grid fluid>
          <Row className="show-grid" fluid="true">
              <Col xs={6} md={6} lg={6}>
                <h4>{this.state.noteName}</h4>
              </Col>
              <Col xs={6} md={6} lg={6}>
                <h5>
                  <b>Authors: </b>
                  {this.state.collaborator ? "You and " + this.state.collaborator : "Only You"}
                  <br/>
                  <b>Readers: </b>
                  {this.state.sharedWith ? "You and " + this.state.sharedWith : "Only You"}
                </h5>
              </Col>
          </Row>
        </Grid>
        {this.state.note &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="content" className="notesContent">
              <FormControl
                onChange={this.handleChange}
                value={this.state.content}
                componentClass="textarea"
              />
            </FormGroup>
            <LoaderButton
              block
              bsSize="lg"
              variant="outline-secondary"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save my five lines"
              loadingText="Saving…"
            />
          </form>}
      </div>
    );
  }*/
}