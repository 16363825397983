import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Grid, Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Home.css";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const notes = await this.notes();
      this.setState({ notes });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  notes() {
    return API.get("notes", "/notes");
  }

  deleteNote(noteId) {
    return API.del("notes", `/notes/${noteId}`);
  }

  handleDelete = (noteId) => async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this note?" + noteId
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteNote(noteId);
      this.props.history.push("/");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  renderNotesList(notes) {
    return (
      <div>
        <LinkContainer
            key="new" 
            to="/notes/new"
          >
            <ListGroupItem>New Notebook</ListGroupItem>
        </LinkContainer>
        {notes.map(
          (note, i) =>
            <LinkContainer
              key={note.noteId}
              to={`/notes/${note.noteId}`}
            >
                <ListGroupItem>
                  {note.noteName}
                  <h5 className="noteDetails">
                    <b>Created: </b>
                    {new Date(note.createdAt).toLocaleString()}
                  </h5>
                </ListGroupItem>
            </LinkContainer>
          )
        }
      </div>
    );
  }

  /*renderNotesList(notes) {
    return (
      <div>
        <LinkContainer
            key="new" 
            to="/notes/new"
          >
            <ListGroupItem>Create a new notebook</ListGroupItem>
        </LinkContainer>
        {notes.map(
          (note, i) =>
            <LinkContainer
              key={note.noteId}
              to={`/notes/${note.noteId}`}
            >
                <ListGroupItem>
                  {note.noteName}
                  <h5 className="noteDetails">
                    <b>Authors: </b>
                    {note.collaborator ? "You and " + note.collaborator : "Only You"}
                    <br/>
                    <b>Readers: </b> 
                    {note.sharedWith ? "You and " + note.sharedWith : "Only You"}
                  </h5>
                </ListGroupItem>
            </LinkContainer>
          )
        }
      </div>
    );
  }*/

  renderNotes() {
    return (
      <div className="notes">
        <h4>Your Notebooks</h4>
        <ListGroup>
          {!this.state.isLoading && this.renderNotesList(this.state.notes)}
        </ListGroup>
      </div>
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>My Five Lines</h1>
        <p>A simple, minimal and secure space for your thoughts, observations and stories.</p>
      </div>
    );  
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}