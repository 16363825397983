export default {
    s3: {
	REGION: "us-west-2",
	BUCKET: "notes-attachments-uploads-1"
    },
    apiGateway: {
	REGION: "us-west-2",
	URL: "https://kcec2w41wj.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
	REGION: "us-west-2",
	USER_POOL_ID: "us-west-2_TkDLa1pWj",
	APP_CLIENT_ID: "7iumgqrgls8hnth6a73tbu5gou",
	IDENTITY_POOL_ID: "us-west-2:3f3808a7-1a82-4a89-be5c-dad92b9fb51d"
     }
};